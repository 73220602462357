/*
 * @Descripttion:
 * @Author: wuxinxin
 * @Date: 2LastEditTime022-02-09 09:34:50
 * @LastEditors: wangru
 * @: 2022-02-09 09:34:51
 */
import http from '@/utils/http';
/**
 * @description:  试用申请确认
 * @param {*} formdata
 * @return {*}
 */
export function agenciesIntentionClient(formdata: any): any {
  return http.post(
    `/agency-resource/1/agenciesIntentionClient/intentionAgencies`,
    formdata
  );
}
/**
 * @description:  申请试用获取验证码
 * @param {*} mobile
 * @return {*}
 */
export function mobile_smsCode(mobile: any): any {
  return http.get(`/auth-resource/1/5/${mobile}/smsCode`);
}
/**
 * @description:  优惠券领取
 * @param {*} formdata
 * @return {*}
 */
export function coupons_receive(formData: any): any {
  return http.post(`/marketing-resource/1/bizCouponR/receive`, formData);
}
/**
 * @description:  获取省市区
 * @param {*} formdata
 * @return {*}
 */
export function sys_sysRegion(param): any {
  return http.get(`/sys-resource/1/sysRegion/list`, param);
}
/**
 * @description:  获取token
 * @param {*} formdata
 * @return {*}
 */
export function submitToken(): any {
  return http.get(`/sys-resource/1/repeatSubmit/submitToken`);
}
/**
 * @description:  优惠券获取验证码
 * @param {*} mobile
 * @return {*}
 */
export function receiveCouponVerifyCode(mobile: any): any {
  return http.get(
    `/passport-resources/1/passport/receiveCouponVerifyCode/${mobile}`
  );
}
/**
 * @description:  优惠券获取验证码
 * @params {*}
 * @return {*}
 */
export function preOrderInfo(params: any): any {
  return http.post('/order-resource/1/preOrderInfo', params);
}
/**
 * @description: 试用申请
 * @param {*} formdata
 * @return {*}
 */
export function newCoupons_receive(formData: any): any {
  return http.post(`/passport-resources/1/pub/passport/trial`, formData);
}

/**
 * @description:  计价申请用户获取验证码
 * @param {*} mobile
 * @return {*}
 */
export function receiveNewCouponVerifyCode(mobile: any): any {
  return http.get(`/auth-resource/1/9/${mobile}/smsCode`);
}
